// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #463A3A;
$color-primary-background: #fff;
$color-error: #a74a2f;
$color-error-background: #faeded;
$color-success: #2a815f;
$color-promo-background: #c4e6d8;
$color-notification-background: #d8d8d8;
$color-input-border: #a0a0a0;
$color-accent: #DD5061;
$color-sort: #463A3A;

$color-secondary-text: #463A3A;
$color-secondary:#DD5061;
$color-process-Black:#231F20;

// ## Shades ##
$color-shade_1: #252525;
$color-shade_2: #4d4d4d;
//$color-shade_3: #757575;
$color-shade_3: #716F70;
$color-shade_4: #d8d8d8;
$color-shade_5: #71706D;
$color-shade_6: #fafafa;
$color-shade_7: #403F3E;
$color-shade_8: rgba(198,196,191,0.15);

// ## ACCORDION ##
$color-accordion-item-border: $color-shade_5 !default;

// ## GH/GF colors ##
$color-header_text: $color-secondary;
$color-header_alt: $color-secondary;
$color-footer_text: $color-secondary-text;
$color-footer_alt: $color-secondary-text;

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-primary; //~1
$color-button_main-border: $color-primary; //~1
$color-button_main-text: $color-primary-background; //~1
$color-button_main__hover-background: $color-primary-background; //~1
$color-button_main__hover-border: $color-primary; //~1
$color-button_main__hover-text: $color-primary; //~1

$color-button_main__inversed-background: $color-primary-background; //~
$color-button_main__inversed-border: $color-primary; //~
$color-button_main__inversed-text: $color-primary; //~
$color-button_main__inversed__hover-background: $color-primary;
$color-button_main__inversed__hover-border: $color-primary;
$color-button_main__inversed__hover-text: $color-primary-background;
// ### Alt ###
$color-button_alt-background: $color-primary-background;
$color-button_alt-border: $color-primary;
$color-button_alt-text:  $color-primary;
$color-button_alt__hover-background:$color-primary;
$color-button_alt__hover-border: $color-primary;
$color-button_alt__hover-text: $color-primary-background;

$color-button_alt__inversed-background: $color-primary;
$color-button_alt__inversed-border: $color-primary;
$color-button_alt__inversed-text: $color-primary-background;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-primary;
$color-button_alt__inversed__hover-text: $color-primary;
// ## Badges ##
$color-badge-1: $color-secondary;
$color-badge-2: $color-secondary-text;
//============================================


// ## PROGRESS BAR ##
$color-progress-bg: $color-shade_5; //$color-shade_5 ;
$color-progress-value-bg: $color-secondary;
$progress-border-config: 1px solid $color-secondary; //added


// ## LINKS ##
$color-link: $color-secondary;
$color-link__visited: $color-badge-2; //fix
$color-link__disabled: $color-shade_4; //fix

// ## BREADCRUMBS ##
$color-breadcrumb-current: $color-primary !default;
$color-breadcrumb-separator_bg: $color-secondary !default;
$color-breadcrumb-link_color: $color-secondary!default;

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary;
$color-text__light: $color-primary-background;
$color-overlay-background: $color-primary;

// # COMPONENTS #
// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;
$color-product_tile-promo: $color-primary;

// ## PRODUCT BADGE ##
$color-badge_2-color: $color-badge-1;

// ## Header ##
$color-header-background: #fbf4ed;
$color-header__transparent-text: $color-header_alt;
$color-header__transparent__inverted-text: $color-header_text;
$color-header__transparent-background-border: rgba($color-primary-background, 0.15);
$color-header__transparent-header_promo-background: $color-promo-background;
$color-header__transparent-header_promo-color: $color-primary;
$color-navigation_flyout-background: $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-shade_6;
$color-hamburger_account-background: $color-shade_6;
$color-cookies-background: $color-shade_5;//??
$color-header_promo__transparent_header-background: $color-badge-2;//+
$color-main_nav-divider: $color-shade_5;//??
$color-mobile_navigation-categories_divider: $color-shade_5;//+
$color-mobile_navigation-section_divider: $color-shade_4;

// ## Footer ##
$color-footer-background: $color-primary;

// # CART AND CHECKOUT #
// ## CHECKOUT ##
$color-checkout-card_icon: $color-primary-background;

// ## PRODUCT WISHLIST CTA ##
$color-wishlist: $color-accent;

// # AGE GATE #
$color-age_gate-text: $color-primary !default;
$color-age_gate-background: $color-primary-background;

// # ERROR PAGES #
$color-error_404-text: $color-primary;

// ## CAROUSEL ##
$color-carousel_pagination_control: $color-primary-background;
$color-carousel_pagination_control__active: $color-secondary;
$color-carousel_control: $color-secondary;
$color-carousel_control__disabled: #CDC8C8;

// #SVG icon color
$color-svg-icon: $color-secondary;