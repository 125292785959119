// stylelint-disable

// # Source Sans Pro

// ## Regular

// ### latin-ext
// @import url("https://use.typekit.net/mxd7vmm.css");

@import url('https://use.typekit.net/nsx5ymz.css');
//New Fonts
// $font-awconqueror: aw-conqueror-inline, sans-serif;
// $font-termina: termina, serif;
$font-sweet-sans: sweet-sans-pro, sans-serif;

$font-main: $font-sweet-sans;
$font-alt: $font-sweet-sans;
